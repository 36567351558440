@import url('https://fonts.googleapis.com/css2?family=Mulish&display=swap');
body {
  margin: 0;
  font-family: "Mulish";
  -webkit-font-smoothing: antialiased;
  height: 100vh;
}


input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}