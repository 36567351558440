.react-tel-input .flag-dropdown .country-list {
  background-color: #ccc;
}

.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  background-color: transparent !important;
}

@media screen and (max-width: 900px) {
  .react-tel-input .flag-dropdown .country-list {
    background-color: white;
  }
}
.react-tel-input .flag-dropdown.open .selected-flag {
  background: transparent !important;
}
